/*  MOb Version */

@mixin s {
  @media screen and (max-width: 330px) {
    @content;
  }
}
@mixin sm {
  @media screen and (max-width: 600px) {
    @content;
  }
}

/*  Tab Version */
@mixin md {
  @media (min-width: 601px) and (max-width: 1024px) {
    @content;
  }
}

@mixin md1 {
  @media (min-width: 1025px) and (max-width: 1400px) {
    @content;
  }
}
@mixin lg {
  @media (min-width: 1401px) and (max-width: 1550px) {
    @content;
  }
}

@mixin xl {
  @media screen and (min-width: 1551px) and (max-width: 1799px) {
    @content;
  }
}
@mixin xxl {
  @media screen and (min-width: 1800px) {
    @content;
  }
}

@mixin FlexBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin Flex($justify, $align) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
}

@mixin hr {
  border-bottom: 1px solid;
  padding-bottom: 0.9rem;
  border-width: 0.1rem;
  border-color: rgba(153, 151, 151, 0.437);
}

@mixin btnTran {
  background-color: transparent;
  border: 1px solid var(--gold);
  color: var(--gold);
  padding: 0.6rem 1rem;
  border-radius: 8px;
}

@mixin btnNormal {
  background-color: var(--gold) !important;
  color: white;
  padding: 0.6rem 1rem;
  border-radius: 8px;
}
@mixin btnRed {
  background-color: rgba(239, 46, 51, 1);
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-weight: 500;
}
@mixin btnYellow {
  background-color: #c8743e;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-weight: 500;
}

@mixin lightBox {
  box-shadow: 1px 1px 10px rgba(128, 128, 128, 0.115);
}

@mixin lightBorder {
  border: 1px solid rgba(221, 212, 212, 0.319);
}
@mixin normalBorder {
  border: 1px solid var(--border);
}

@mixin MidiumBox {
  box-shadow: 1px 1px 10px rgba(88, 88, 88, 0.326);
}

@mixin Heading($Size, $weight, $pos, $color) {
  font-size: $Size;
  font-weight: $weight;
  text-align: $pos;
  color: var($color);
}

@mixin smt {
  @media (min-width: 601px) and (max-width: 991px) {
    @content;
  }
}

@mixin search($width) {
  width: $width;
  background: var(--gradient);
  padding: 0.5em 1em;
  border: 1px solid var(--border);
  border-radius: 5px;
  color: var(--white);
}
