@import "../../mixin.scss";
.Candidate {
  h6 {
    color: var(--gold);
  }
  .GridCandidate {
    display: grid;
    grid-template-columns: 130px 140px 140px 140px 140px 270px 110px 100px;
    .box {
      margin-bottom: 1em;
      label {
        font-size: 0.8em;
        color: rgb(0, 0, 0);
      }
      p {
        margin-top: 0.3em;
        font-size: 1em;
        font-weight: 600;
      }
    }
  }
  .GridLanguage {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    .box {
      @include Flex(start, center);
      gap: 1em;
      margin-bottom: 1em;
      label {
        font-size: 0.92em;
        color: rgb(189, 188, 188);
      }
      p {
        margin-top: 0.3em;
        font-size: 0.96em;
      }
    }
  }
}

.heading {
  font-size: 1.1em;
}
.btnYellow {
  @include btnYellow;
}

.errors {
  color: red;
  font-weight: 600;
  margin-bottom: 5px;
  //   text-transform: capitalize;
  text-align: left !important;
  font-size: 13px;
  margin-top: 3px;
  text-wrap: nowrap;
}

.NewInput {
  input {
    height: 50px !important;
    width: 100% !important;
  }
}
.table-border {
  border: 1px solid rgb(172, 171, 171);
  border-radius: 6px;
  overflow: hidden;
}

.GlobalTable {
  .MuiTableContainer-root {
    max-height: 260px;
    overflow: auto;
    scroll-behavior: smooth;
  }
  th {
    text-wrap: nowrap;
    text-align: center;
    padding: 10px;
  }
  td {
    text-wrap: nowrap;
    padding: 0;
    line-height: 32px;
    min-height: 32px;
  }
}

.GlobalTable .MuiTableHead-root {
  position: sticky; /* Make the header sticky */
  top: 0; /* Stick the header to the top */
  z-index: 1; /* Ensure the header is above the body when scrolling */
}

.GlobalTable10 {
  overflow: auto;
  .MuiTableContainer-root {
    max-height: 400px;
    overflow: auto;
    scroll-behavior: smooth;
  }
  th {
    text-wrap: nowrap;
    // background-color: rgba(109, 109, 109, 0.657);
    text-align: center;
    padding: 10px;
  }
  td {
    text-wrap: nowrap;
    padding: 0;
    line-height: 32px;
    min-height: 32px;
  }
}

.GlobalTable10 .MuiTableHead-root {
  position: sticky; /* Make the header sticky */
  top: 0; /* Stick the header to the top */
  z-index: 1; /* Ensure the header is above the body when scrolling */
}
.GlobalTable7 {
  .MuiTableContainer-root {
    max-height: 315px;
    overflow: auto;
    scroll-behavior: smooth;
  }
  th {
    // background-color: rgba(109, 109, 109, 0.657);
    text-align: center;
    padding: 10px;
  }
  td {
    padding: 0;
    line-height: 32px;
    min-height: 32px;
  }
}

.GlobalTable7 .MuiTableHead-root {
  position: sticky; /* Make the header sticky */
  top: 0; /* Stick the header to the top */
  z-index: 1; /* Ensure the header is above the body when scrolling */
}

.Pagination_table {
  padding: 1em;
  width: 100%;
  @include Flex(end, center);
  .PageBox {
    @include Flex(end, center);
    margin-right: 2em;
    span {
      font-weight: 600;
      font-size: 0.9em;
    }
    .number{
      font-size: 1.1em;
      margin: auto 4px;
    }
  }
  td {
    background: transparent !important;
    border-bottom: none !important;
  }
  svg {
    font-size: 1.5em !important;
    cursor: pointer;
    color: black;
    &:hover{
      background: rgb(201, 198, 198);
      border-radius: 50px;
    }
  }
  .base-TablePagination-select:focus {
    outline: none !important;
    border-color: #f7f7f7 !important;
  }
  .base-TablePagination-displayedRows {
    display: none !important;
  }
}
