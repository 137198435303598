@import "../../../mixin.scss";
.ProfileWeb {
  padding: 130px 50px 70px 50px;
  max-width: 90%;
  margin: auto;
  img {
    border: 2px solid rgb(169, 165, 165);
  }
  .Pr_img {
    position: relative;
    width: 110px;
    height: 110px;
    border-radius: 0%;
    @include md {
      width: 100px;
    }
    .Pr_box_img {
      width: 115px;
      height: 115px;
      overflow: hidden;
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 0%;
      object-fit: cover;
    }
    svg {
      position: absolute;
      top: -6%;
      color: #f4a84e;
      right: -15px;
      font-size: 2em;
    }
    input {
      cursor: pointer;
      height: 120px;
      position: absolute;
      top: 0px;
      width: 120px;
      z-index: 10;
      opacity: 0;
    }
  }
}
