@import "./mixin.scss";
.AddInquiryModal {
  padding: 1.8em !important;
  min-width: 1150px !important;
  max-width: 1150px !important;
  padding: 1em 1.5em;
}
.ScheduleModal {
  min-width: 600px;
  max-width: 600px;
  padding: 1.9em 1.7em;
}
.AppointStatusModal {
  min-width: 500px;
  max-width: 500px;
  padding: 1.9em 1.7em;
}

.AddBillExpense {
  padding: 1.8em !important;
  min-width: 800px !important;
  max-width: 800px !important;
  padding: 1em 1.5em;
}
.HandoverAssets {
  padding: 1.8em !important;
  min-width: 800px !important;
  max-width: 800px !important;
  padding: 1em 1.5em;
}
.NewAssetsRequestModal {
  padding: 1.8em !important;
  min-width: 1020px !important;
  max-width: 1020px !important;
  padding: 1em 1.5em;
  .ModalGrid {
    margin-top: 1em;
    display: grid;
    gap: 1em;
    padding: 0 1.3em;
    align-items: center;
    grid-template-columns: 270px 200px 200px 200px;
  }
  .setFlexAction {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-top: 1.5em;
  }
}

.AddTaskModal {
  padding: 1.8em !important;
  min-width: 950px !important;
  max-width: 950px !important;
  padding: 1em 1.5em;
}
.ViewModalSlider {
  min-width: 700px !important;
  max-width: 700px !important;
  padding: 3em 1.5em !important;
  .sliderOwl {
    position: relative;
    .owl-nav {
      position: absolute;
      top: 35%;
      z-index: 10;
      width: 115%;
      left: -45px;
      display: flex;
      justify-content: space-between;
      button {
        span {
          display: block;
          font-size: 3em;
        }
      }
    }
    .owl-dots {
      margin-top: 1em;
    }
  }
}

.ExtendProbationModal {
  min-width: 550px !important;
  max-width: 550px !important;
  padding: 2em 1.5em !important;
}

.AllocateAssetsModal {
  padding: 1.8em !important;
  min-width: 900px !important;
  max-width: 900px !important;
  padding: 1em 1.5em;
}
.AssetReturnModalOfc {
  padding: 1.8em !important;
  min-width: 500px !important;
  max-width: 500px !important;
  padding: 1em 1.5em;
}
.ViewModalCV {
  padding: 1.8em !important;
  min-width: 500px !important;
  max-width: 500px !important;
  padding: 1em 1.5em;
}
.AddJobPositionModal {
  padding: 1.8em !important;
  min-width: 900px !important;
  max-width: 900px !important;
  padding: 1em 1.5em;
  @include sm{
    padding: 1.8em 1em !important;
    min-width: 250px !important;
    max-width: 500px !important;

  }
}
.JobApplicationModal {
  padding: 1.8em !important;
  min-width: 500px !important;
  max-width: 500px !important;
  padding: 1em 1.5em;
}
.AddTrainModal {
  padding: 1.8em !important;
  min-width: 700px !important;
  max-width: 700px !important;
  padding: 1em 1.5em;
  label {
    z-index: 0 !important;
  }
}

.AddMeetSchedule {
  padding: 1.8em !important;
  min-width: 800px !important;
  max-width: 800px !important;
}
.Hr_performModal {
  padding: 1.8em !important;
  min-width: 900px !important;
  max-width: 900px !important;
}
.GoalAwardModal {
  padding: 1.8em !important;
  min-width: 500px !important;
  max-width: 500px !important;
}
.modalContent {
  margin: .8em 0;
  padding: 1em;
  border: 1px solid rgb(176, 173, 173);
  border-radius: 5px;
}
.Modal300 {
  position: relative;
  padding: 1em 1.5em !important;
  min-width: 300px !important;
  max-width: 300px !important;
}
.Modal400 {
   padding: 1em 1.5em !important;
  min-width: 400px !important;
  max-width: 400px !important;
}
.Modal500 {
   padding: 1em 1.5em !important;
  min-width: 500px !important;
  max-width: 500px !important;
}
.Modal600 {
   padding: 1em 1.5em !important;
  min-width: 600px !important;
  max-width: 600px !important;
}
.Modal700 {
   padding: 1em 1.5em !important;
  min-width: 700px !important;
  max-width: 700px !important;
}
.Modal800 {
   padding: 1em 1.5em !important;
  min-width: 800px !important;
  max-width: 800px !important;
}
.Modal900 {
   padding: 1em 1.5em !important;
  min-width: 900px !important;
  max-width: 900px !important;
}
.Modal1000 {
  padding: 1em 1.5em !important;
  min-width: 1000px !important;
  max-width: 1000px !important;
}
.Modal1200 {
  padding: 1em 1.5em !important;
  min-width: 1200px !important;
  max-width: 1200px !important;
}
// ------- GRIDS --------//
.GridFive {
  display: grid;
  padding: 0 1.5em;
  gap: 1.5em;
  grid-template-columns: repeat(5, 1fr);
  label {
    z-index: 0;
  }
}
