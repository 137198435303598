@import "../../../mixin.scss";
.HomeBanner {
  .BannerCategory {
    @include sm {
      left: 70px;
      // top: 200px;
      bottom: 350px;
      z-index: 1;
      display: flex;
      flex-direction: column;
    }
  }

  .bannerHeading {
    @include sm {
      flex-direction: column;
      gap: 20px;
      align-items: start;
    }
  }

  .BannerCategoryName {
    @include sm {
      @include Flex(start, center);
      width: 100%;
      padding: 0;
      margin: 0;
    }
    button {
      @include sm {
        &:first-child {
          padding-left: 0;
          margin-left: 0;
        }
      }
    }
  }
}

.btnRed{
  @include btnRed;
}

